import { isBrowser } from "../../../../services/general"
import { parse, stringify } from "flatted/esm"

export const getContextFromSession = () =>
  isBrowser() && sessionStorage.getItem("patientEnrollmentState")
    ? parse(sessionStorage.getItem("patientEnrollmentState"))
    : {}

export const saveContextToSession = (state) => {
  if (isBrowser())
    sessionStorage.setItem("patientEnrollmentState", stringify({ ...state }))
}

export const removeContextFromSession = () => {
  if (isBrowser()) sessionStorage.removeItem("patientEnrollmentState")
}
