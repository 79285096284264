import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import classNames from "classnames"
import styles from "./utils/elements.module.scss"

const Modal = (props) => {
  const modalBody = props.isCard ? (
    <ModalCardBody {...props} />
  ) : (
    <ModalBody {...props} />
  )

  return (
    <div
      className={classNames("modal m-0 is-hidden-mobile px-4", {
        "is-active": props.isModalActive,
      })}
    >
      <div
        role="button"
        onKeyDown={(event) => {
          if (event.key === "Enter") props.closeModal()
        }}
        aria-label="Modal Background"
        tabIndex={0}
        className={`modal-background has-background-${props.modalBackground?.color} has-opacity-${props.modalBackground?.opacity}`}
        onClick={props.closeModal}
      ></div>
      {modalBody}
    </div>
  )
}

const ModalCardBody = (props) => (
  <div
    className={classNames("modal-card p-0", {
      [styles["modal__cardFullheight"]]: props.isFullheight,
    })}
  >
    {props.heading && (
      <div
        className={classNames(
          "modal-card-head",
          props.modalHeaderClass,
          {
            "has-background-white": !props.modalHeaderClass,
          },
          { [styles["modal__cardHeaderFullheight"]]: props.isFullheight }
        )}
      >
        <p
          className={classNames(
            "modal-card-title has-text-primary",
            props.modalHeaderClass
          )}
        >
          {props.heading}
        </p>
        {!props.hideCloseButton && (
          <button
            className={classNames("delete is-medium")}
            onClick={props.closeModal}
          >
            Close
          </button>
        )}
      </div>
    )}
    <div
      className={classNames(
        "modal-card-body has-text-centered",
        styles["modal__cardBody"]
      )}
    >
      {props.children}
    </div>
  </div>
)

const ModalBody = (props) => {
  const data = useStaticQuery(graphql`
    {
      camera: file(relativePath: { eq: "icons/camera.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const camera = data.camera.childImageSharp.fixed

  return (
    <Fragment>
      <div
        className={classNames(
          "modal-content has-text-centered px-2",
          styles["modal__scrollbarIsHidden"]
        )}
      >
        {!props.hideCloseButton && (
          <button
            className="delete has-background-grey"
            onClick={props.closeModal}
            style={{ position: "absolute", top: "5%", right: "5%" }}
          >
            Close
          </button>
        )}
        {props.heading && (
          <div className="is-flex is-flex-direction-column">
            {props.icon && (
              <div className="is-grey mb-1">
                <Img fixed={camera} />
              </div>
            )}
            <h3 className="mb-2 has-text-grey">{props.heading}</h3>
          </div>
        )}

        {props.children}
      </div>
    </Fragment>
  )
}

export default Modal
