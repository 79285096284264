import { useStaticQuery, graphql } from "gatsby"

const ALL_AIRTABLE_PE_FIELDS_QUERY = graphql`
  query {
    allAirtableallPatientEnrollmentFormFields(
      filter: { data: { Product: { eq: "Patient Enrollment" } } }
    ) {
      nodes {
        data {
          Name
          Firestore_Key
          Type
          Initial_Values
          Field_Names
          Module
          Section
          Show_On_Website
          Show_On_Zendesk
          Zendesk_Key
          Zendesk_Order
          Placeholder
          Validation
          Date_Config
          Required
          Follow_Up_Questions
          Follow_Up_Questions_Name
          Is_Follow_Up_Question
        }
      }
    }
  }
`

export const useAirtablePatientEnrollmentFormFields = () => {
  const data = useStaticQuery(ALL_AIRTABLE_PE_FIELDS_QUERY)

  return data
}
