import { authInitialState } from "./authInitialState"
import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "./services/context"

const form = {
  SAVE_DETAILS: "SAVE_DETAILS",
  DISABLE_BUTTON: "DISABLE_BUTTON",
  SET_INCORRECT_BIRTHDAY_ATTEMPT: "SET_INCORRECT_BIRTHDAY_ATTEMPT",
}

const OTP = {
  SET_OTP: "SET_OTP",
  SET_OTP_VERIFIER: "SET_OTP_VERIFIER",
  SET_OTP_SHOW_RESEND_MESSAGE: "SET_OTP_SHOW_RESEND_MESSAGE",
  SET_OTP_RESEND_COUNT: "SET_OTP_RESEND_COUNT",
}
const auth = {
  SAVE_AUTH: "SAVE_AUTH",
}

const session = {
  SAVE_CONTEXT_TO_SESSION: "SAVE_CONTEXT_TO_SESSION",
  GET_CONTEXT_FROM_SESSION: "GET_CONTEXT_FROM_SESSION",
  REMOVE_CONTEXT_FROM_SESSION: "REMOVE_CONTEXT_FROM_SESSION",
}

export let AuthReducer = (state, action) => {
  switch (action.type) {
    case session.SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...toBeSaved })
      return toBeSaved
    case session.GET_CONTEXT_FROM_SESSION:
      return { ...getContextFromSession() }

    case session.REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {}
    case auth.SAVE_AUTH:
      return {
        ...state,
        ...action.payload,
      }
    case form.SAVE_DETAILS:
      return { ...state, ...action.payload }
    case form.DISABLE_BUTTON:
      return { ...state, buttonStatus: action.payload }
    case form.SET_INCORRECT_BIRTHDAY_ATTEMPT:
      return { ...state, incorrectBirthdayAttempt: action.payload }

    case OTP.SET_OTP:
      return { ...state, otp: action.payload }
    case OTP.SET_OTP_VERIFIER:
      return { ...state, otpVerifier: action.payload }
    case OTP.SET_OTP_SHOW_RESEND_MESSAGE:
      return { ...state, otpShowResendMessage: action.payload }
    case OTP.SET_OTP_RESEND_COUNT:
      return { ...state, otpResendCount: action.payload }

    default:
      return { ...authInitialState }
  }
}
