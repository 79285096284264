import React, { useReducer } from "react"

import { DoctorEnrollmentReducer } from "./DoctorEnrollmentReducer"
import { useAirtableDoctorEnrollmentFormFields } from "./hooks/useAirtableDoctorEnrollmentFormFields"

import { generateInitialValues } from "../../../services/context"

let { flattenNode } = require("../../../services/arrays")

const DoctorEnrollmentContext = React.createContext()

const DoctorEnrollmentProvider = ({ children }) => {
  let formFields = useAirtableDoctorEnrollmentFormFields()
  formFields = flattenNode(formFields?.allAirtableallDoctorEnrollmentFormFields)

  let [doctorEnrollmentState, doctorEnrollmentDispatch] = useReducer(
    DoctorEnrollmentReducer,
    {
      ...generateInitialValues({ fields: formFields }),
      documents: [],
      initialState: {
        ...generateInitialValues({ fields: formFields }),
        documents: [],
        consentAndAuthorization: [],
      },
    }
  )

  return (
    <DoctorEnrollmentContext.Provider
      value={{ doctorEnrollmentState, doctorEnrollmentDispatch }}
    >
      {children}
    </DoctorEnrollmentContext.Provider>
  )
}

export { DoctorEnrollmentContext, DoctorEnrollmentProvider }
