import { isObjectEmpty } from "../../../services/general"
import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "../Doctor/services/context"

export const initialState = {
  agreeToMechanics: [],
  consentAndAuthorization: [],
  consentNovoNordisk: [
    'I consent to be engaged by Novo Nordisk Pharmaceuticals (Philippines), Inc. and its service providers via digital channels and mobile phone for updates on our CMEs, promotions, and materials related to our products, patient support, and Saxenda<sup>®</sup> Patient Support Program. <span class="has-text-grey is-italic">(Optional)</span>',
  ],
}

const forms = {
  SAVE_DOCTOR_ENROLLMENT: "SAVE_DOCTOR_ENROLLMENT",
}

const consent = {
  SAVE_AGREE_CONSENT: "SAVE_AGREE_CONSENT",
  SAVE_AGREE_NOVO_NORDISK: "SAVE_AGREE_NOVO_NORDISK",
}

const context = {
  SAVE_CONTEXT_TO_SESSION: "SAVE_CONTEXT_TO_SESSION",
  GET_CONTEXT_FROM_SESSION: "GET_CONTEXT_FROM_SESSION",
  REMOVE_CONTEXT_FROM_SESSION: "REMOVE_CONTEXT_FROM_SESSION",
}

export const DoctorEnrollmentReducer = (state, action) => {
  switch (action.type) {
    case forms.SAVE_DOCTOR_ENROLLMENT:
      return { ...state, ...action.payload }
    case consent.SAVE_AGREE_CONSENT:
      return { ...state, consentAndAuthorization: action.payload }
    case consent.SAVE_AGREE_NOVO_NORDISK:
      return { ...state, consentNovoNordisk: action.payload }
    case context.SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...initialState, ...state, ...toBeSaved })
      return { ...initialState, ...state, ...toBeSaved }
    case context.GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession() }
      if (isObjectEmpty(returnState)) return { ...initialState }
      return { ...initialState, ...returnState }
    case context.REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...initialState,
      }
    default:
      return { ...state, ...state.initialState }
  }
}
