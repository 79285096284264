import React from "react"
import { ApolloProvider } from "react-apollo"
import { client } from "./src/services/apollo"

import "./static/styles/global.scss"
import { getFirebase } from "./src/services/firebase"
import { AppProvider } from "./src/context/AppContext"
import { AuthProvider } from "./src/context/AuthContext"
import { DoctorEnrollmentProvider } from "./src/components/Enrollment/EnrollmentContext/DoctorEnrollmentContext"
import { EpharmacyProvider } from "./src/components/Epharmacy/EpharmacyContext/EpharmacyContext"

import { PatientEnrollmentProvider } from "./src/components/Enrollment/EnrollmentContext/PatientEnrollmentContext"

export const wrapRootElement = ({ element }) => {
  getFirebase()

  return (
    <AppProvider>
      <AuthProvider>
        <DoctorEnrollmentProvider>
          <EpharmacyProvider>
            <PatientEnrollmentProvider>
              <ApolloProvider client={client}>{element}</ApolloProvider>
            </PatientEnrollmentProvider>
          </EpharmacyProvider>
        </DoctorEnrollmentProvider>
      </AuthProvider>
    </AppProvider>
  )
}
