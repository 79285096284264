import { isObjectEmpty } from "../../../services/general"
import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "../Patient/services/context"

export const initialState = {
  agreeToMechanics: [],
  documents: [],
  consentAndAuthorization: [],
}

const forms = {
  SAVE_PATIENT_ENROLLMENT: "SAVE_PATIENT_ENROLLMENT",
}

const upload = {
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
}

const consent = {
  SAVE_AGREE_CONSENT: "SAVE_AGREE_CONSENT",
}

const context = {
  SAVE_CONTEXT_TO_SESSION: "SAVE_CONTEXT_TO_SESSION",
  GET_CONTEXT_FROM_SESSION: "GET_CONTEXT_FROM_SESSION",
  REMOVE_CONTEXT_FROM_SESSION: "REMOVE_CONTEXT_FROM_SESSION",
}

export const PatientEnrollmentReducer = (state, action) => {
  switch (action.type) {
    case upload.SAVE_DOCUMENTS:
      return { ...state, documents: [...action.payload] }
    case forms.SAVE_PATIENT_ENROLLMENT:
      return { ...state, ...action.payload }
    case consent.SAVE_AGREE_CONSENT:
      return { ...state, consentAndAuthorization: action.payload }
    case context.SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...initialState, ...state, ...toBeSaved })
      return { ...initialState, ...state, ...toBeSaved }
    case context.GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession() }
      if (isObjectEmpty(returnState)) return { ...initialState }
      return { ...returnState }
    case context.REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...initialState,
      }
    default:
      return { ...state, ...state.initialState }
  }
}
