import { isBrowser } from "../../../services/general"
import { parse, stringify } from "flatted/esm"

export const getContextFromSession = () =>
  isBrowser() && sessionStorage.getItem("epharmacyState")
    ? parse(sessionStorage.getItem("epharmacyState"))
    : {}

export const saveContextToSession = (state) => {
  if (isBrowser())
    sessionStorage.setItem("epharmacyState", stringify({ ...state }))
}

export const removeContextFromSession = () => {
  if (isBrowser()) sessionStorage.removeItem("epharmacyState")
}
