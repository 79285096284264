// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-enrollment-complete-index-js": () => import("./../../../src/components/Enrollment/Complete/index.js" /* webpackChunkName: "component---src-components-enrollment-complete-index-js" */),
  "component---src-components-enrollment-doctor-form-index-js": () => import("./../../../src/components/Enrollment/Doctor/Form/index.js" /* webpackChunkName: "component---src-components-enrollment-doctor-form-index-js" */),
  "component---src-components-enrollment-doctor-summary-index-js": () => import("./../../../src/components/Enrollment/Doctor/Summary/index.js" /* webpackChunkName: "component---src-components-enrollment-doctor-summary-index-js" */),
  "component---src-components-enrollment-patient-form-index-js": () => import("./../../../src/components/Enrollment/Patient/Form/index.js" /* webpackChunkName: "component---src-components-enrollment-patient-form-index-js" */),
  "component---src-components-enrollment-patient-form-summary-js": () => import("./../../../src/components/Enrollment/Patient/Form/Summary.js" /* webpackChunkName: "component---src-components-enrollment-patient-form-summary-js" */),
  "component---src-components-epharmacy-checkout-js": () => import("./../../../src/components/Epharmacy/Checkout.js" /* webpackChunkName: "component---src-components-epharmacy-checkout-js" */),
  "component---src-components-epharmacy-complete-index-js": () => import("./../../../src/components/Epharmacy/Complete/index.js" /* webpackChunkName: "component---src-components-epharmacy-complete-index-js" */),
  "component---src-components-epharmacy-order-index-js": () => import("./../../../src/components/Epharmacy/Order/index.js" /* webpackChunkName: "component---src-components-epharmacy-order-index-js" */),
  "component---src-components-epharmacy-payment-details-js": () => import("./../../../src/components/Epharmacy/PaymentDetails.js" /* webpackChunkName: "component---src-components-epharmacy-payment-details-js" */),
  "component---src-components-epharmacy-summary-js": () => import("./../../../src/components/Epharmacy/Summary.js" /* webpackChunkName: "component---src-components-epharmacy-summary-js" */),
  "component---src-components-epharmacy-upload-index-js": () => import("./../../../src/components/Epharmacy/Upload/index.js" /* webpackChunkName: "component---src-components-epharmacy-upload-index-js" */),
  "component---src-components-static-pages-about-index-js": () => import("./../../../src/components/StaticPages/About/index.js" /* webpackChunkName: "component---src-components-static-pages-about-index-js" */),
  "component---src-components-static-pages-static-page-js": () => import("./../../../src/components/StaticPages/StaticPage.js" /* webpackChunkName: "component---src-components-static-pages-static-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

