import { useStaticQuery, graphql } from "gatsby"

const ALL_AIRTABLE_EPHARMACY_FIELDS_QUERY = graphql`
  query {
    allAirtableallEpharmacyFormFields(
      filter: { data: { Product: { eq: "EPharmacy" } } }
    ) {
      nodes {
        data {
          Name
          Type
          Initial_Values
          Field_Names
          Is_Future_Dates_Only
          Module
          Section
          Label
          Section_Order
          Section_Link
          Section_Required
          Order
          Show_On_Website
          Show_On_Zendesk
          Zendesk_Key
          Zendesk_Order
        }
      }
    }
  }
`

export const useAirtableEpharmacyFormFields = () => {
  const data = useStaticQuery(ALL_AIRTABLE_EPHARMACY_FIELDS_QUERY)
  return data
}
