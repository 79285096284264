export const authInitialState = {
  otp: "",
  email: "",
  password: "",
  // email: "gadizon+saxenda01@medgrocer.com",
  // password: "MedGrocer456!",
  confirmPassword: "",
  otpVerifier: null,
  birthday: {
    month: {
      value: "",
    },
    date: {
      value: "",
    },
    year: {
      value: "",
    },
  },
  hasBeenVerified: false,
  otpShowResendMessage: false,
  otpResendCount: 1,
  incorrectBirthdayAttempt: false,
}
