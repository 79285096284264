import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "../services/context"
import { isObjectEmpty } from "../../../services/general"

export const initialState = {
  documents: [],
  medicines: [],
  agreeToMechanics: [],
}

const forms = {
  SAVE_EPHARMACY: "SAVE_EPHARMACY",
}

const upload = {
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
  SAVE_ID_TYPE: "SAVE_ID_TYPE",
}

const order = {
  SAVE_MEDICINES: "SAVE_MEDICINES",
  SAVE_SELECTED_MEDICINE: "SAVE_SELECTED_MEDICINE",
}

const consent = {
  SAVE_AGREE_CONSENT: "SAVE_AGREE_CONSENT",
  SAVE_AGREE_PILLS_SPLIT: "SAVE_AGREE_PILLS_SPLIT",
}

const context = {
  SAVE_CONTEXT_TO_SESSION: "SAVE_CONTEXT_TO_SESSION",
  GET_CONTEXT_FROM_SESSION: "GET_CONTEXT_FROM_SESSION",
  REMOVE_CONTEXT_FROM_SESSION: "REMOVE_CONTEXT_FROM_SESSION",
}
export const EpharmacyReducer = (state, action) => {
  switch (action.type) {
    case upload.SAVE_DOCUMENTS:
      return { ...state, documents: [...action.payload] }

    case upload.SAVE_ID_TYPE:
      return { ...state, idType: action.payload }

    case order.SAVE_MEDICINES:
      return { ...state, medicines: [...action.payload] }

    case order.SAVE_SELECTED_MEDICINE:
      return { ...state, selectedMedicine: action.payload }

    case forms.SAVE_EPHARMACY:
      return { ...state, ...action.payload }

    case consent.SAVE_AGREE_CONSENT:
      return { ...state, consentAndAuthorization: action.payload }

    case consent.SAVE_AGREE_PILLS_SPLIT:
      return { ...state, pillSplitAgreement: action.payload }

    case context.SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...initialState, ...state, ...toBeSaved })
      return { ...initialState, ...state, ...toBeSaved }
    case context.GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession() }
      if (isObjectEmpty(returnState)) return { ...initialState }
      return { ...returnState }
    case context.REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...initialState,
      }
    default:
      return { ...state, ...state.initialState }
  }
}
